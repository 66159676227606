import { type FC } from 'react';

const RegistrationProcess: FC = () => (
  <div className="space-y-1">
    <p className="mb-1 text-lg leading-relaxed text-gray-500 dark:text-gray-400">
      Growth Support へのご登録ありがとうございます！
      <br />
      以下のブログ記事のステップに従い、Google Analytics
      のプロパティを登録してください
    </p>
    <a
      href="https://growth-support.hatenablog.com/entry/2024/04/20/084916"
      target="_blank"
      rel="noreferrer"
      className="bg-transparent text-blue-500 font-semibold py-2 px-4 border border-blue-400 rounded inline-block"
    >
      Growth Support 登録手順
    </a>
  </div>
);

export default RegistrationProcess;
